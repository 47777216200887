/*
 * @Author: xia 10434208+xiaoyang0226@user.noreply.gitee.com
 * @Date: 2023-02-06 10:59:36
 * @LastEditors: xia 10434208+xiaoyang0226@user.noreply.gitee.com
 * @LastEditTime: 2023-11-08 17:37:15
 * @FilePath: /com.huizhouyiren.c1102.web/src/store/modules/user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
	用户信息相关
*/
import request from "@/api/request.js";
import requestPath from "@/api/path.js";
import router from "@/router/index.js";
import { Message } from "element-ui";

export default {
  namespaced: true,
  state: {
    loginFlag: false, // 登录状态
    userInfo: null, // 用户信息
  },
  mutations: {
    // 更新登录状态
    updateLoginFlag(state, flag) {
      state.loginFlag = flag;
    },
    // 更新用户信息
    updateUserInfo(state, info) {
      state.userInfo = info;
    },
    // 退出登录
    logout(state) {
      state.userInfo = null;
      uni.clearStorageSync();
    },
  },
  actions: {
    webLogin({ commit }, data) {
      request({
        url: requestPath.login,
        method: "POST",
        data: data,
      }).then((res) => {
        if (res.code == 200) {
          commit("updateLoginFlag", true);
          commit("updateUserInfo", res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          Message.success("登录成功");
          setTimeout(() => {
            router.push({
              path: "TestList",
              query: {
                id: data.id,
                grade: data.grade,
                class: data.class,
                type: data.type,
              },
            });
          }, 500);
        }
      });
    },
  },
};
