/*
 * @Author: yanghaolong 2226764294@qq.com
 * @Date: 2023-02-06 10:41:27
 * @LastEditors: xia 10434208+xiaoyang0226@user.noreply.gitee.com
 * @LastEditTime: 2023-11-09 09:58:18
 * @FilePath: /com.huizhouyiren.c1102.web/src/api/path/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
	登录模块相关接口
*/
export const route = {
  // 学生登录
  login: "/psychology/v1/site/student-login",

  // 答题列表
  questionList: "/psychology/v1/student/question",

  // 答题
  question: "/psychology/v1/student/ask",

  // 答题类型列表
  questionTypeList: "/psychology/v1/mht/question",

  // MHT答题
  mhtQuestion: "/psychology/v1/mht/ask",

  // SCl答题
  sclQuestion: "/psychology/v1/scl/ask",

  // PHQ答题
  phqQuestion: "/psychology/v1/phq/ask",
};
