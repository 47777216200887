// 封装请求
import axios from "axios";
import { Message } from "element-ui";
import { debounce } from "@/utils/tools.js";
// 请求
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 6000,
});
// 请求拦截
request.interceptors.request.use(
  (config) => {
    //请求头设置
    let token = localStorage.getItem("token") || "";
    if (token) {
      config.headers.token = "Bearer " + token;
    }

    return config;
  },
  (err) => {
    console.log(err);
  }
);
// 响应拦截
request.interceptors.response.use(
  (res) => {
    let { code, message } = res.data;
    // 对响应码的处理
    switch (code) {
      case 401:
      case 40005:
      case 407:
      case 500:
        Message({
          message: message,
          type: "error",
        });
        return;
      // case 200:
      //   Message({
      //     message: message,
      //     type: "success",
      //   });
      //   return;
      case 201:
        Message({
          message: message,
          type: "success",
        });
        return;
      case 204:
        Message({
          message: message,
          type: "success",
        });
        return;
      case 400:
        Message({
          message: message,
          type: "warning",
        });
        return;
      case 401:
        Message({
          message: message,
          type: "warning",
        });
        return;
      case 403:
        Message({
          message: message,
          type: "warning",
        });
        return;
      case 404:
        Message({
          message: message,
          type: "warning",
        });
        return;
      case 422:
        Message({
          message: message,
          type: "warning",
        });
        return;
    }
    return res.data;
  },
  (err) => {
    console.log(err);
  }
);
// 返出
export default request;
