import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: "/",
    redirect: "/RouterMain/HomeLogin",
  },
  {
    path: "/RouterMain",
    name: "RouterMain",
    component: () => import("../views/RouterMain/RouterMain.vue"),
    redirect: "/RouterMain/HomeLogin",
    children: [{
      path: "HomeLogin",
      name: "HomeLogin",
      component: () => import("../views/Home-List/HomeLogin.vue"), // 首页
    },{
      path: "TestList",
      name: "TestList",
      component: () => import("../views/Test-List/TestList.vue"), // 首页
    }]
  },
]

/**
 * 以下四行代码是为了解决下面的这个问题
 * 关于VUE项目中报NavigationDuplicated: Avoided redundant navigation to current location 的错
 */
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 500);
      });
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;