import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'

import request from "./api/request";
import requestPath from "./api/path";

// 封装的axios请求
Vue.prototype.$request = request;
// 请求路径
Vue.prototype.$requestPath = requestPath;

Vue.config.productionTip = false

Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
